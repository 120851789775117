import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    SiVisualstudiocode,
    SiPostman,
    SiSlack,
    SiVercel,
    SiMacos,
    SiJetbrains,
    SiDevdotto,
    SiGoogle,
    SiWebstorm,
    SiYarn,
    SiDocker,
    SiVite,
    SiWebpack,
    SiEslint,
    SiFigma,
    SiGithubactions,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiGithubactions/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFigma/>
      </Col>
        <Col xs={4} md={2} className="tech-icons">
            <SiVite/>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <SiJetbrains/>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <SiDocker/>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <SiWebpack/>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <SiEslint/>
        </Col>
    </Row>
  );
}

export default Toolstack;
